import {initTrustbars} from "../../../default/global/js/trustbar";
import {getChildrenClassForNestedItems} from "../../../default/global/js/nav";
import {closeFilterBox, initFilter} from "../../../default/global/js/filter";
import {initActionClicks, initFormSubmitChanges, initFormSubmitClicks} from "../../../default/global/js/actionClick";
import {focusInputGroups} from "../../../default/global/js/checkInputGroups";
import {stickyHeader} from "../../../default/global/js/stickyheader";
import {triggerFavoritesBtns} from "../../../default/global/js/favorites";
import {initQuantitySpinners} from "../../../default/global/js/quantitySpinner";
import hoverintent, {removeOtherHoverintents} from "../../../default/global/js/hoverintent";
import {initSlideshows} from "../../../default/global/js/slideshow";
import {openFullscreenGallery} from "../../../default/global/js/fullscreengallery";
import {stickyBox} from "../../../default/global/js/stickyBox.js";
import {buildMobileMenu, hideMobileMenu, showMobileMenu} from "../../../default/global/js/mobilemenu";
import {excludeModal, triggerConfirmModalLink, triggerModal} from "../../../default/global/js/modal";
import {triggerBasketBtns, triggerUpdateBasketView} from "../../../default/global/js/basket";
import {initToggleSlide} from "../../../default/global/js/toggle";
import {initItemlistSlider} from "./custom/itemListSlider";
import {setSrcVideos} from "../../../default/global/js/video";
import {initScrollbars} from "../../../default/global/js/scrollbar";
import {isTouchDevice} from "../../../default/global/js/touchDevice";
import {initCustomSelects} from "../../../default/global/js/customSelect";
import {initJumpmarks} from "../../../default/global/js/jumpmark";
import {initSearchItems} from "../../../default/global/js/searchItems";
import {initItemlistToggle} from "../../../default/global/js/itemlistToggle";
import {initPriceListGraduated} from "../../../default/global/js/priceListGraduated";
import {lottieLoader} from "../../../default/global/js/lottieLoader";
import {initFlashmessages} from "../../../default/global/js/flashmessages";
import {initPasswordFields} from "../../../default/global/js/password";
import {trimInputValues} from "../../../default/global/js/formValidator";
import {initSlidecontents} from "../../../default/global/js/slidecontent";
import {initPhoneCountryFields} from "../../../default/global/js/phoneCountryField";
import {initInputBody} from "../../../default/global/js/inputfile";
import {scrollAnimatedText,scrollAnimatedTextTop   } from "../../../richterspielgeraeteGlobal/src/js/animatedText";
import {inizializeCollectionSwiper} from "./custom/collectionSpielraeume";
import {initchronicSwiper} from "./custom/chronicSlider";
import {breadcrumbInBanner} from "./custom/breadcrumbInBanner";
import {initImageGallery} from "../../../default/global/js/imageGallery";
import {setMarginTopToContainer} from "./custom/offsetTopElement";
import {initAjaxCollectionFilter} from "../../../default/global/js/ajaxCollectionFilter";
import {algoliaDoAutoComplete} from "./algolia/algoliaAutoComplete";
import {algoliaSearch, setupAlgoliaFilterWrappers, toggleAlgoliaSearch, updateSelectedFilters, toggleAlgoliaSearchNew} from "./algolia/algolia";
import {countMail} from "../../../richterPortal/src/js/custom/documentEmailCount";
import {updateSelectedOption} from "../../../richterspielgeraeteGlobal/src/js/updateSelectedOption";
import {triggerComparisonBtns} from "../../../default/global/js/compare";
import {initRecaptchaForms} from "../../../default/global/js/recaptchaForm";
import {expandText} from "./custom/showMoreText";
import {modalSlider} from "./custom/modalSlider";
import Swiper from "swiper";

window.addEventListener("load", (event) => {
    const mobileMenu = document.querySelector('.mobileMenu');
    const mobileMenuToggle = document.getElementById("js-toggleNavigation");
    const overlay = document.getElementById("overlay");
    const IsTouchDevice = isTouchDevice();
    const stickyHeaderArea = document.querySelector('.headerMain');

    let breakPointSM = 1024;
    let sendFavoriteByJS = false;
    let sendItemToBasketByJS = false;
    let updateBasketByJS = false;

    lottieLoader();



    if (typeof GLOBALS!== "undefined") {
        breakPointSM = GLOBALS.gridBreakpoints.sm;
        sendFavoriteByJS = GLOBALS.sendFavoriteByJS;
        //sendItemToBasketByJS = GLOBALS.sendItemToBasketByJS;
        updateBasketByJS = GLOBALS.updateBasketByJS;
    }

    if (mobileMenu && mobileMenuToggle && overlay) {
        const mobileMenuClose = mobileMenu.querySelector(".mobileMenu__close");

        buildMobileMenu(mobileMenu);

        mobileMenuToggle.addEventListener("click", function () {
            showMobileMenu(mobileMenu, overlay, mobileMenuToggle);
        })

        mobileMenuClose.addEventListener("click", function () {
            hideMobileMenu(mobileMenu, overlay, mobileMenuToggle);
        });

        overlay.addEventListener("click", function () {
            hideMobileMenu(mobileMenu, overlay, mobileMenuToggle);
            for (let filterbox of document.querySelectorAll('.filterBox')) {
                closeFilterBox(filterbox);
            }
        });
    }

    const tabs = document.querySelector(".tabWrapper");
    const tabButton = document.querySelectorAll(".tab-button");
    const contents = document.querySelectorAll(".tabContent");

    if(tabs) {
        tabs.onclick = e => {
            const id = e.target.dataset.id;
            if (id) {
                tabButton.forEach(btn => {
                    btn.classList.remove("active");
                });
                e.target.classList.add("active");

                contents.forEach(content => {
                    content.classList.remove("active");
                });
                const element = document.getElementById(id);
                element.parentElement.classList.add("active");
            }
        }
    }

    initActionClicks();

    initFormSubmitClicks();

    initFormSubmitChanges();

    algoliaSearch();

    algoliaDoAutoComplete();

    toggleAlgoliaSearch();

    setupAlgoliaFilterWrappers();

    updateSelectedFilters();

    initTrustbars();

    initFilter();

    focusInputGroups();

    trimInputValues();

    initInputBody();

    initPasswordFields();

    initPhoneCountryFields();

    stickyHeader(stickyHeaderArea);

    initchronicSwiper();

    modalSlider();


    if (window.outerWidth < breakPointSM) {
        for (let parent of document.querySelectorAll('.navgroup > ul > li')) {
            getChildrenClassForNestedItems(parent);
        }
    }

    if (sendFavoriteByJS) {
        triggerFavoritesBtns();
    }

    if (sendItemToBasketByJS) {
        triggerBasketBtns();
    }

    if (updateBasketByJS) {
        triggerUpdateBasketView();
    }

    triggerModal();
    excludeModal();
    triggerConfirmModalLink();

    initQuantitySpinners();

    initToggleSlide();

    initFlashmessages();

    if (!IsTouchDevice) {
        for (let el of document.querySelectorAll('.itembox,.iconbarItem')) {
            hoverintent(el);
        }
    }

    if (IsTouchDevice) {
        for (let el of document.querySelectorAll('.js-mainNavigation > ul > li > a')) {
            el.addEventListener("click", function (e) {
                let li = el.closest("li");
                if (!li.classList.contains("hoverintent")) {
                    removeOtherHoverintents();
                    li.classList.add("hoverintent");
                    e.preventDefault();
                } else {
                    li.classList.remove("hoverintent");
                }
            })
        }
    } else {
        for (let el of document.querySelectorAll('.js-mainNavigation > ul > li')) {
            hoverintent(el);
        }
    }

    setSrcVideos();

    initSlideshows();

    initScrollbars();

    initSlidecontents();

    initItemlistSlider(document.querySelectorAll('.itemboxList.swiper'),{
        breakpointItems: {
            xs: 1.7,
            sm: 2.5,
            md: 3,
            lg: 4,
            xlg: 4
        }
    });

    initCustomSelects();

    initSearchItems(document.querySelectorAll('.js-searchInput'));

    let offsetTopJumpmarks = 0;

    if (stickyHeaderArea) {
        offsetTopJumpmarks = stickyHeaderArea.clientHeight + 25;
    }

    if (typeof GLOBALS!== "undefined") {
        if(GLOBALS.offsetTopJumpmarks){
            offsetTopJumpmarks += GLOBALS.offsetTopJumpmarks;
        }
    }
    initJumpmarks(offsetTopJumpmarks);

    for (let item of document.querySelectorAll(".js-openGallery")) {
        item.addEventListener('click', function (event) {
            openFullscreenGallery(item);
        });
    }

    let offsetTopStickyBox = 30;
    if (stickyHeaderArea) {
        offsetTopStickyBox += stickyHeaderArea.clientHeight;
    }

    for (let pinBox of document.querySelectorAll(".js-stickyBox")) {
        stickyBox(pinBox, {
            offsetTop: offsetTopStickyBox
        });
    }


    initItemlistToggle();
    initPriceListGraduated();

    scrollAnimatedText();

    scrollAnimatedTextTop();

    inizializeCollectionSwiper();

    breadcrumbInBanner();

    initImageGallery();

    setMarginTopToContainer();

    initAjaxCollectionFilter();

    toggleAlgoliaSearchNew();

    updateSelectedOption();

    triggerComparisonBtns();

    initRecaptchaForms();

    expandText();

});