import Swiper, {Autoplay, Navigation, Scrollbar} from 'swiper';
import {getOptionsFromJsonString} from "../../../../default/global/js/utilities/options";

export function initchronicSwiper(query = document.querySelectorAll('.chronic__list')) {
    for (let slideshow of query) {
        Swiper.use([Autoplay, Navigation, Scrollbar]);
        new Swiper(slideshow, {
            slidesPerView: 1.25,
            spaceBetween: 20,
            navigation: {
                nextEl: slideshow.querySelector('.swiper-button-next'),
                prevEl: slideshow.querySelector('.swiper-button-prev'),
            },
            breakpoints: {
                768: {
                    spaceBetween: 40,
                    slidesPerView: 1.75,
                },
                1024: {
                    spaceBetween: 40,
                    slidesPerView: 2.5,
                },
                1500: {
                    spaceBetween: 64,
                    slidesPerView: 2.5,
                },
                1850: {
                    spaceBetween: 72,
                    slidesPerView: 2.5,
                }
            }
        });
    }
}