export function setMarginTopToContainer() {
    const screenWidth = window.innerWidth;

    const tileBoxElements = document.querySelectorAll('.js-offsetTop--50');

    tileBoxElements.forEach(tileBox => {
        const container = tileBox.closest('.container');

        if (container) {
            const tileBoxHeight = tileBox.clientHeight;
            const marginTopValue = '-' + tileBoxHeight / 2 + 'px';

            container.style.marginTop = marginTopValue;
        }
    });
}