import {loadScript} from "./utilities/loadScript";

export function lottieLoader() {
    let lottieWrapper = document.querySelectorAll('.js-lottie');
    if(lottieWrapper.length > 0){
        loadScript('/plugins/lottie/lottie.min.js').then(() => {
            for (let lottieLoader of lottieWrapper) {
                let item = lottie.loadAnimation({
                    wrapper: lottieLoader,
                    animType: 'svg',
                    loop: true,
                    autoplay: true,
                    path: lottieLoader.dataset.path,
                });
            }
        }).catch(() => {
            console.log('Error loading lottie');
        });
    }

    let lottieWrapperHover = document.querySelectorAll('.js-lottie-hover');
    if(lottieWrapperHover.length > 0){
        loadScript('/plugins/lottie/lottie.min.js').then(() => {
            for (let lottieLoader of lottieWrapperHover) {

                let item = lottie.loadAnimation({
                    wrapper: lottieLoader,
                    animType: 'svg',
                    loop: false,
                    autoplay: true,
                    hover: true,
                    path: lottieLoader.dataset.path,
                });

                item.addEventListener('complete', () => {
                    document.getElementById('logoAnimated').classList.remove('hidden');
                    document.getElementById('logoDummy').classList.add('hidden');
                });

                var directionMenu = 1;
                let logoHover = document.querySelector('.logoAnimated');
                if(logoHover) {
                    document.addEventListener('load', (e) => {
                        item.setDirection(directionMenu);
                        item.play();
                    });
                    logoHover.addEventListener('mouseenter', (e) => {
                        item.setDirection(directionMenu);
                        item.play();
                    });

                    logoHover.addEventListener('mouseleave', (e) => {
                        item.setDirection(-directionMenu);
                        item.play();
                    });
                }

            }
        }).catch(() => {
            console.log('Error loading lottie');
        });
    }
}