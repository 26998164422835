import {slideUp} from "./utilities/slide";
import {setCookie} from "./utilities/cookie";
import Swiper, {Autoplay} from "swiper";
import {getOptionsFromJsonString} from "./utilities/options";

function generateCloseBtn() {
    let btnCloseBar = document.createElement("div");
    let btnCloseBarIcon = document.createElement("i");
    btnCloseBar.classList.add("trustBar__close");
    btnCloseBarIcon.classList.add("icon");
    btnCloseBarIcon.classList.add("icon-close");
    btnCloseBar.appendChild(btnCloseBarIcon);

    return btnCloseBar;
}

function buildTrustbarSlider(bar) {

    bar.classList.add("swiper");

    let swiperWrapper = document.createElement("div");
    swiperWrapper.classList.add("swiper-wrapper");

    for (let content of bar.querySelectorAll('.textcontent')) {
        content.classList.add("swiper-slide");
        swiperWrapper.appendChild(content);
    }

    bar.appendChild(swiperWrapper);
}

function startTrustbarSlider(bar, options = {}) {

    let childrenCount = bar.querySelectorAll('.swiper-wrapper > *').length;

    let breakpoints = {
        xs: {
            items: 1,
            autoplay: childrenCount > 1
        },
        sm: {
            items: 2,
            autoplay: childrenCount > 2
        },
        md: {
            items: 3,
            autoplay: childrenCount > 3
        }
    }

    if (options.items < 2) {
        breakpoints.sm.items = options.items;
        if (options.items < childrenCount) {
            breakpoints.sm.autoplay = true;
        }
    }

    if (options.items < 3) {
        breakpoints.md.items = options.items;
        if (options.items < childrenCount) {
            breakpoints.md.autoplay = true;
        }
    }

    Swiper.use([Autoplay]);

    let swiperElement = new Swiper(bar, {
        loop: true,
        speed: options.eff_interval,
        allowTouchMove: false,
        spaceBetween: options.spaceBetween,
        breakpoints: {
            0: {
                slidesPerView: breakpoints.xs.items,
                autoplay: {
                    enabled: breakpoints.xs.autoplay
                }
            },
            768: {
                slidesPerView: breakpoints.sm.items,
                autoplay: {
                    enabled: breakpoints.sm.autoplay
                }
            },
            1024: {
                slidesPerView: breakpoints.md.items,
                autoplay: {
                    enabled: breakpoints.md.autoplay
                }
            }
        },
        autoplay: {
            delay: options.autoplay_interval,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        }
    });
}

export function initTrustbars(query = document.querySelectorAll('.js-trustBar')) {
    for (let bar of query) {

        let options = getOptionsFromJsonString(bar.getAttribute('data-options'), {
            autoplay_interval: 5000,
            eff_interval: 300,
            spaceBetween: 20,
            items: 1,
            hasCloseBtn: false
        });

        buildTrustbarSlider(bar);
        startTrustbarSlider(bar, options);

        if (options.hasCloseBtn) {
            let btnCloseBar = generateCloseBtn();
            bar.appendChild(btnCloseBar);

            btnCloseBar.addEventListener("click", function () {
                slideUp(bar, 200, "linear", function () {
                    setCookie('trustBarHidden', true, 7);
                });
            });
        }
    }
}